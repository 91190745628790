'use strict';

/**
 * chartsPainel
 */
; (function (angular) {

    angular
        .module('appchartsPainel', [
            'vs-repeat',
            'gc-find',
            'gc-ajax',
            'gc-transform',
            'gc-form',
            'gc-utils',
            'ngFileUpload',
            'ui.utils.masks',
            'ngCookies'
        ]);

    angular
        .module('appchartsPainel')
        .value('gScope', {})
        .controller('CrtchartsPainel', CrtchartsPainel);

    CrtchartsPainel.$inject = [
        '$scope',
        '$ajax',
        '$timeout',
        '$rootScope',
        '$q',
        '$compile',
        'gScope',
        '$sce',
        '$cookies',
    ];

    var elm = document.getElementById('idchartsPainel');
    angular.bootstrap(elm, ['appchartsPainel']);

    function CrtchartsPainel($scope, $ajax, $timeout, $rootScope, $q, $compile, gScope, $sce, $cookies) {
        var vm = this;

        vm.CHEVRON_TOGGLE_INFORMACOES = false;
        vm.PAINEL_CHART_VISIBLE       = false;
        vm.LISTA_PANEL                = [];
        vm.PANEL_SELECTED             = [];

        var timeCarregarTelaModal = setTimeout(function() {},100);
        var AbrindoTelaModal      = 0;
        var funcFechar            = function(){};

        var metaContent = $('meta[name="menu"]').attr('content');
        var extractedValue = metaContent.split('_')[1];

        vm.usuario_id = $('#usuario-id').val();
        vm.menu_id    = extractedValue;

        vm.toggleInformacoes = function(){
            vm.CHEVRON_TOGGLE_INFORMACOES = !vm.CHEVRON_TOGGLE_INFORMACOES;
        }

        vm.fecharModal = function(){
            $('#modal-chartsPainel').modal('hide');
        }

        vm.init = function(){
            vm.LISTA_PANEL = [];  
            vm.loadPanels();          
        }

        vm.OpenPanel = function(painel){
            vm.PANEL_SELECTED =  painel;
            vm.CHEVRON_TOGGLE_INFORMACOES = !vm.CHEVRON_TOGGLE_INFORMACOES;
            
            //$('#modal-chartsPainel').modal('show');
            
            vm.abrirTelaEmModal(painel.CODIGO+' - '+painel.NOME,'/_28000/'+vm.PANEL_SELECTED.ID+'?FILTRAR=1', function(){
                go_tela_aberto1 = false;
            });
            
        }
        
        vm.abrirTelaEmModal = function(titulo, url, eventClose){

            if(AbrindoTelaModal == 0){
                
                AbrindoTelaModal = 1;
    
                $('#modal-abrir-tela').find('.img-loading-abrir-tela').css('display','block');
    
                funcFechar = function(){};
                if(eventClose){
                    funcFechar = eventClose;
                }
    
                $('#titulo-modal-abrir-tela').html(titulo);
    
                var proj = $('.abrir_tela');
                proj.attr('src', url);
    
                $('#modal-abrir-tela').modal('show');
    
                try {
                    clearTimeout(timeCarregarTelaModal);
                } catch (error) {}
    
                timeCarregarTelaModal = setTimeout(function() {
                    $('#modal-abrir-tela').find('.img-loading-abrir-tela').css('display','none');
                    AbrindoTelaModal = 0;  
                },2000);
    
            }
    
        }

        vm.loadPanelData = function(){
            var dados = {
                DADOS : {
                    RELATORIO_ID : vm.PANEL_SELECTED.ID
                }
            };
        
            return $q(function(resolve, reject){
                $ajax.post('/_11081/api/getDadosPanelCharts', dados).then(function(response){
                    vm.LISTA_PANEL = response;
                    vm.PAINEL_CHART_VISIBLE = vm.LISTA_PANEL.length > 0;
                    resolve(response);
                },function(e){
                    reject(e);
                });
            });
        }

        vm.loadPanels = function() {
            var dados = {
                DADOS: {
                    USUARIO_ID: vm.usuario_id,
                    TELA_ID: vm.menu_id
                }
            };

            if (dados.DADOS.TELA_ID > 0 && dados.DADOS.TELA_ID != '25800') {
                return $q(function(resolve, reject) {
                    $ajax.post('/_11081/api/getPanelCharts', dados).then(function(response) {
                        vm.LISTA_PANEL = response;
                        vm.PAINEL_CHART_VISIBLE = vm.LISTA_PANEL.length > 0;

                        if (vm.PAINEL_CHART_VISIBLE) {
                            let grafico = vm.LISTA_PANEL[0];
                            vm.OpenPanel(grafico);
                        }

                        resolve(response);
                    }, function(e) {
                        reject(e);
                    });
                });
            }
        }
    };

})(angular);

(function ($) {

})(jQuery);